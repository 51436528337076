import { NotificationType } from '@local/web-design-system';
import { Dispatch } from 'redux';
import {
    ADD_SNACKBAR_NOTIFICATION,
    CLEAR_CHART_DATA,
    GET_CHART_DATA,
    LOAD_CHART_OPTIONS,
} from 'state-domains/types/actionTypes';
import { typeComplete, typeFail, typePending } from 'state-domains/utils';
import { FilterObject } from 'state-domains/domain/drillhole';
import { QAQC_CHART_TYPES } from 'state-domains/constants/mxdeposit';

import { reportingShim } from '../shim';
import { ChartFilters } from '../types';

const loadChartOptions = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_CHART_OPTIONS) });
    reportingShim.loadChartOptions().subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(LOAD_CHART_OPTIONS), payload: response });
        },
        error: (error: Error) => {
            dispatch({ type: typeFail(LOAD_CHART_OPTIONS), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const getChartData =
    (type: QAQC_CHART_TYPES, requiredFilters: ChartFilters, optionalFilters: FilterObject[]) =>
    (dispatch: Dispatch) => {
        dispatch({ type: typePending(GET_CHART_DATA) });
        reportingShim.loadChartData(type, requiredFilters, optionalFilters).subscribe({
            next: (response: any) => {
                const filterData = {
                    labCode: requiredFilters.labServiceCode,
                    analyte: requiredFilters.analyte,
                    unit: requiredFilters.unit,
                };
                dispatch({
                    type: typeComplete(GET_CHART_DATA),
                    payload: { data: response, filters: filterData },
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(GET_CHART_DATA), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
    };

const clearChartData = () => (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_CHART_DATA });
};

export const actions = { loadChartOptions, getChartData, clearChartData };
